
.hubspot-form {

  /* Title */
  .form-columns-0:first-child > .hs-main-font-element {
    @apply text-body-large font-semibold mb-8 text-plum text-left;
  }

  form {
    @apply w-full;
  }
  fieldset {
    @apply !max-w-full;
  }
  .hs-form-field{
    @apply !mb-2 !max-w-full;
  }
  .input {
    @apply !mr-0;
  }
  .form-columns-2 {
    @apply grid sm:grid-cols-2 gap-3;
    > * {
      @apply col-span-1 !float-none !w-auto;
    }
  }
  .hs-form-required {
    @apply hidden;
  }
  .hs-form-field {
    > label {
      @apply text-body-tiny text-plum;
    }

    .input {
      @apply pt-1;
    }

    .hs-input[type="text"], .hs-input[type="email"] {
      @apply text-body-standard text-plum-80 w-full pl-4 h-14 border border-plum-16 rounded-lg outline-none focus:border-plum-80;
      &.error {
        @apply border-orange;
      }
    }
    select.hs-input {
      @apply !w-full text-body-standard relative flex items-center rounded-lg border bg-white pl-4 focus:outline-none focus-within:border-plum h-14 border-plum-16 text-plum appearance-none bg-dropdown bg-right-4 bg-no-repeat;
      &.is-placeholder {
        @apply text-plum-40
      }
      &.error {
        @apply border-orange;
      }
    }
    .hs-input[type="checkbox"] {
      @apply mr-2 accent-plum !mt-1;
    }
  }
  .hs-email .hs-error-msgs {
  }
  .hs-firstname .hs-error-msgs {
  }
  .hs-lastname .hs-error-msgs {
  }
  .hs-company .hs-error-msgs {
  }
  .hs-jobtitle .hs-error-msgs {
  }
  .hs-error-msgs {
    @apply mt-1 text-body-tiny text-orange block;
  }

  .actions {
    @apply flex flex-row items-center justify-center;
  }
  .hs-button.primary.large {
    @apply text-body-small font-medium min-w-[144px] inline-flex cursor-pointer items-center justify-center text-center select-none whitespace-nowrap ring-2 ring-inset h-11 rounded-full px-8 col-span-6 mx-auto mt-4 w-full md:w-auto md:min-w-[200px] text-beige bg-plum hover:bg-plum-80 active:bg-plum-64 focus:outline-[black] disabled:bg-plum-16 disabled:hover:bg-plum-16 disabled:text-plum-64 ring-transparent;
  }
  .legal-consent-container {
    @apply text-body-tiny relative mb-2 text-center text-plum-64;
    a {
      @apply font-medium hover:underline;
    }
  }
  .hs-form-booleancheckbox {
    @apply flex items-center justify-center self-center;
    > label > input {
      @apply mt-1;
    }
    > label > span {
      @apply inline ml-0;
    }
  }

  .hs_error_rollup {
    @apply hidden;
  }

  .submitted-message {
    @apply text-center;
    h1 > span > img {
      @apply mx-auto;
    }
    h1 > span {
      @apply text-h1 mt-4 block;
    }
    h1 + p {
      @apply text-body-standard mt-1;
    }
  }
  /* Legal consent */
  .form-columns-0 .hs-richtext {
    @apply text-body-tiny relative mb-2 text-center text-plum-64;
    a {
      @apply font-medium hover:underline;
    }
  }

  .hs-richtext {
    @apply text-body-tiny relative mb-2 text-center text-plum-64;
    a {
      @apply font-medium hover:underline;
    }
  }
}

.hubspot-dark {
  .hubspot-form {
    .hs-richtext {
      @apply text-body-tiny relative mb-2 text-center text-beige-dark;
      a {
        @apply font-medium hover:underline;
      }
    }
    .hs-input[type="checkbox"] {
      @apply accent-white;
    }
    .hs-button.primary.large {
      @apply text-plum bg-white ml-0 mt-8;
    }
    .legal-consent-container {
      @apply text-white;
    }
    .hs-form-booleancheckbox {
      @apply justify-start
    }
    .hs-input {
      @apply bg-transparent border border-white;
    }
    .hs-input[type="text"], .hs-input[type="email"] {
      @apply text-beige placeholder:text-beige-64 border border-white focus:border-beige;
    }
    select.hs-input {
      @apply bg-transparent border border-white focus-within:border-white text-white appearance-none bg-dropdown-white bg-right-4 bg-no-repeat;
      &.is-placeholder {
        @apply text-white
      }
    }
  }
}
