.slick-track {
  display: flex !important;
}

.slick-track .slick-slide {
  height: auto !important;
}

.slick-track .slick-slide > div {
  height: 100% !important;
}

.slick-dots li button:before {
  font-size: 8px !important;
}

.slick-dots li {
  margin: 0 0px !important;
}

.text-white .slick-dots li button:before {
  color: white !important;
}

.text-beige .slick-dots li button:before {
  color: #EDE6DD !important;
}

.slick-no-arrow {
  width: auto !important;
  height: auto !important;
}

.slick-no-arrow:before {
  content: none !important;
}


.slick-dots.pager {
  @apply  !flex flex-row gap-x-3 items-center justify-center;
}

.slick-dots.pager li{
  @apply w-auto h-auto !m-0;
}

.slick-dots.pager li button {
  @apply rounded h-[5px] w-8 bg-plum-16 p-0;
}
.slick-dots.pager li.slick-active button {
  @apply bg-plum-80;
}

.slick-dots.pager li button:before{
  @apply content-none;
}
